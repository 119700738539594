import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg overflow-hidden">
        <div className="bg-blue-600 text-white p-6">
          <h1 className="text-3xl font-bold">Privacy Policy for Alternance.space</h1>
          <p className="text-sm mt-2">Last Updated: November 20, 2024</p>
        </div>
        <div className="p-6 space-y-6 text-gray-800">
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-blue-700">1. Introduction and Scope</h2>
            <p className="mb-4">
              Alternance.space is committed to protecting the privacy and personal data of our users. This comprehensive Privacy Policy outlines our practices regarding the collection, use, storage, and protection of user information across our digital platform.
            </p>
            <p>
              Our policy applies to all users, including students, employers, and university partners who interact with our platform, whether through web or mobile interfaces.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-blue-700">2. Data Collection Practices</h2>
            <h3 className="text-xl font-medium mb-3 text-blue-600">2.1 Personal Information Collected</h3>
            <ul className="list-disc list-inside space-y-2 mb-4">
              <li><strong>Mandatory Information:</strong>
                <ul className="list-circle list-inside pl-6">
                  <li>Full Name and Contact Details</li>
                  <li>Date of Birth</li>
                  <li>Educational Institution Information</li>
                  <li>Professional Qualifications and Skills</li>
                </ul>
              </li>
              <li><strong>Optional Information:</strong>
                <ul className="list-circle list-inside pl-6">
                  <li>Professional Photo</li>
                  <li>Social Media Profiles</li>
                  <li>Additional Certifications</li>
                </ul>
              </li>
              <li><strong>Authentication Data:</strong>
                <ul className="list-circle list-inside pl-6">
                  <li>Email Addresses</li>
                  <li>Encrypted Password Hashes</li>
                  <li>OAuth Tokens from Third-Party Authentication (Google, LinkedIn)</li>
                </ul>
              </li>
            </ul>
            <p className="text-sm text-gray-600 italic">
              We are transparent about our data collection and only request information essential for connecting students with alternance opportunities.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-blue-700">3. Data Usage and Processing</h2>
            <h3 className="text-xl font-medium mb-3 text-blue-600">3.1 Primary Use Cases</h3>
            <ul className="list-disc list-inside space-y-3">
              <li>
                <strong>Opportunity Matching:</strong> Algorithmically connect students with relevant job and internship opportunities based on skills, education, and preferences.
              </li>
              <li>
                <strong>Professional Networking:</strong> Enable interaction between students, employers, and educational institutions within our platform.
              </li>
              <li>
                <strong>Performance Analytics:</strong> Improve platform functionality and user experience through aggregated, anonymized data analysis.
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-blue-700">4. Data Security Measures</h2>
            <h3 className="text-xl font-medium mb-3 text-blue-600">4.1 Technical and Organizational Protections</h3>
            <ul className="list-disc list-inside space-y-3">
              <li><strong>Encryption:</strong> 
                <ul className="list-circle list-inside pl-6">
                  <li>AES-256 encryption for data at rest</li>
                  <li>TLS 1.3 for data in transit</li>
                </ul>
              </li>
              <li><strong>Access Controls:</strong>
                <ul className="list-circle list-inside pl-6">
                  <li>Role-based access management</li>
                  <li>Multi-factor authentication</li>
                  <li>Regular security audits</li>
                </ul>
              </li>
              <li><strong>Regular Security Updates:</strong> Continuous monitoring and patching of potential vulnerabilities</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-blue-700">5. User Rights and Controls</h2>
            <h3 className="text-xl font-medium mb-3 text-blue-600">5.1 Data Subject Rights</h3>
            <ul className="list-disc list-inside space-y-3">
              <li><strong>Right to Access:</strong> Request a complete copy of your stored personal data</li>
              <li><strong>Right to Rectification:</strong> Update or correct any inaccurate personal information</li>
              <li><strong>Right to Deletion:</strong> Request complete removal of your account and associated data</li>
              <li><strong>Data Portability:</strong> Export your profile and professional information</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-blue-700">6. Compliance and Legal Framework</h2>
            <p className="mb-4">
              While primarily operating under Tunisian regulations, Alternance.space is committed to aligning with international data protection standards, including GDPR principles and emerging global privacy frameworks.
            </p>
            <h3 className="text-xl font-medium mb-3 text-blue-600">6.1 Jurisdictional Considerations</h3>
            <ul className="list-disc list-inside space-y-2">
              <li>Compliance with Tunisian Data Protection Laws</li>
              <li>Adherence to GDPR principles for European interactions</li>
              <li>Commitment to continuous legal and regulatory updates</li>
            </ul>
          </section>

          <section className="bg-blue-50 p-4 rounded-lg">
            <h2 className="text-2xl font-semibold mb-4 text-blue-700">Contact Information</h2>
            <p>
              For any privacy-related inquiries, please contact our Data Protection Officer:
            </p>
            <p className="mt-2">
              <strong>Email:</strong> <a href="mailto:contact@yalors.tn" className="text-blue-600 hover:underline">contact@yalors.tn</a>
            </p>
            <p className="mt-2 text-sm text-gray-600">
              We are committed to addressing your concerns promptly and transparently.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;