import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg overflow-hidden">
        <div className="bg-blue-600 text-white p-6">
          <h1 className="text-3xl font-bold">Terms and Conditions</h1>
          <p className="text-sm mt-2">Last Updated: November 20, 2024</p>
          <p className="mt-4 text-gray-100">
            Please read these Terms and Conditions carefully before using Alternance.space
          </p>
        </div>

        <div className="p-6 space-y-8 text-gray-800">
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-blue-700">1. Introduction</h2>
            <p className="mb-4">
              Welcome to Alternance.space. By accessing or using our platform, you agree to be bound by these Terms and Conditions. If you disagree with any part of these terms, you may not access our service.
            </p>
            <div className="bg-blue-50 p-4 rounded-lg">
              <p className="text-sm italic">
                Alternance.space is a dedicated platform connecting students with job opportunities while enabling them to continue their studies. Our mission is to facilitate meaningful connections between students, employers, and educational institutions.
              </p>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-blue-700">2. Platform Usage</h2>
            
            <h3 className="text-xl font-medium mb-3 text-blue-600">2.1 Acceptable Use</h3>
            <ul className="list-disc list-inside space-y-2 mb-4">
              <li>Users must provide accurate and truthful information</li>
              <li>Professional and respectful communication is required</li>
              <li>Content must be relevant to educational and professional purposes</li>
              <li>Users must respect intellectual property rights</li>
            </ul>

            <h3 className="text-xl font-medium mb-3 text-blue-600">2.2 Prohibited Activities</h3>
            <ul className="list-disc list-inside space-y-2">
              <li>Creating false or misleading profiles</li>
              <li>Harassment or discriminatory behavior</li>
              <li>Spamming or unauthorized advertising</li>
              <li>Attempting to access restricted areas of the platform</li>
              <li>Sharing access credentials with third parties</li>
              <li>Uploading malicious code or content</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-blue-700">3. Account Management</h2>
            
            <h3 className="text-xl font-medium mb-3 text-blue-600">3.1 Account Creation</h3>
            <ul className="list-disc list-inside space-y-2 mb-4">
              <li>Must be at least 18 years old or have guardian consent</li>
              <li>Complete and accurate profile information required</li>
              <li>One account per individual</li>
              <li>Valid educational institution affiliation needed</li>
            </ul>

            <h3 className="text-xl font-medium mb-3 text-blue-600">3.2 Account Security</h3>
            <ul className="list-disc list-inside space-y-2">
              <li>Users are responsible for maintaining password security</li>
              <li>Immediate notification required for unauthorized access</li>
              <li>Regular security information updates recommended</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-blue-700">4. Job Listings and Applications</h2>
            
            <h3 className="text-xl font-medium mb-3 text-blue-600">4.1 Platform Role</h3>
            <p className="mb-4">
              Alternance.space acts solely as an intermediary platform. We:
            </p>
            <ul className="list-disc list-inside space-y-2 mb-4">
              <li>Do not guarantee employment outcomes</li>
              <li>Are not party to any employment agreements</li>
              <li>Do not influence hiring decisions</li>
              <li>Cannot verify all job listing details</li>
            </ul>

            <h3 className="text-xl font-medium mb-3 text-blue-600">4.2 User Responsibilities</h3>
            <ul className="list-disc list-inside space-y-2">
              <li>Verify job opportunity details independently</li>
              <li>Conduct due diligence on potential employers</li>
              <li>Report suspicious or fraudulent listings</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-blue-700">5. Content and Intellectual Property</h2>
            
            <h3 className="text-xl font-medium mb-3 text-blue-600">5.1 User Content</h3>
            <ul className="list-disc list-inside space-y-2 mb-4">
              <li>Users retain ownership of submitted content</li>
              <li>Grant platform license to display and distribute content</li>
              <li>Responsible for content accuracy and legality</li>
            </ul>

            <h3 className="text-xl font-medium mb-3 text-blue-600">5.2 Platform Content</h3>
            <ul className="list-disc list-inside space-y-2">
              <li>All platform features and designs are proprietary</li>
              <li>No reproduction without explicit permission</li>
              <li>Platform content protected by intellectual property laws</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-blue-700">6. Termination and Suspension</h2>
            <p className="mb-4">
              Alternance.space reserves the right to:
            </p>
            <ul className="list-disc list-inside space-y-2">
              <li>Suspend or terminate accounts for Terms violations</li>
              <li>Remove content that violates our policies</li>
              <li>Restrict access to certain features</li>
              <li>Ban users for repeated violations</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-blue-700">7. Liability and Disclaimers</h2>
            <div className="bg-gray-50 p-4 rounded-lg mb-4">
              <p className="font-medium mb-2">Alternance.space is not liable for:</p>
              <ul className="list-disc list-inside space-y-2">
                <li>Employment outcomes or disputes</li>
                <li>User-generated content accuracy</li>
                <li>Third-party website content</li>
                <li>Service interruptions or technical issues</li>
                <li>Direct or indirect damages from platform use</li>
              </ul>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-blue-700">8. Legal Framework</h2>
            <ul className="list-disc list-inside space-y-2">
              <li>These terms are governed by Tunisian law</li>
              <li>Legal jurisdiction in Tunisian courts</li>
              <li>Terms available in multiple languages (Tunisian law version prevails)</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-blue-700">9. Changes to Terms</h2>
            <p className="mb-4">
              We reserve the right to modify these terms at any time. Users will be notified of significant changes via:
            </p>
            <ul className="list-disc list-inside space-y-2">
              <li>Email notifications</li>
              <li>Platform announcements</li>
              <li>Website updates</li>
            </ul>
          </section>

          <section className="bg-blue-50 p-4 rounded-lg">
            <h2 className="text-2xl font-semibold mb-4 text-blue-700">Contact Us</h2>
            <p>
              For questions about these Terms and Conditions, please contact us at:
            </p>
            <p className="mt-2">
              <strong>Email:</strong> <a href="mailto:legal@alternance.space" className="text-blue-600 hover:underline">legal@alternance.space</a>
            </p>
            <p className="mt-2 text-sm text-gray-600">
              Our legal team will respond to your inquiry within 2 business days.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;