import React, { useState, useContext, } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/authContext';
import { BurgerMenuIn } from './burgerMenuIn';


function NavbarIn() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const navigate = useNavigate();

    const { logout, isAuthenticated } = useContext(AuthContext);
    // console.log('Is authenticated:', isAuthenticated());

    const handleLogout = () => {
        // Call the logout function from the context
        logout();
        navigate('/')
        // isAuthenticated(true)
    };

    return (
        <nav className=" sticky top-0 z-50 bg-white  dark:bg-gray-800  shadow">
            <div className="container px-4 py-3 mx-auto">
                <div className="flex items-center">
                    <div className="flex items-center justify-between w-full">
                        <Link to='/'>
                            <img src="/alternance_logo.png" alt="Alternance Logo" className="w-auto h-6 sm:h-7" />

                        </Link>




                        <div className="hidden lg:flex items-center justify-between   gap-4 -mx-6 lg:flex-row lg:items-center lg:mx-8">

                            <Link to="/offers" className="px-3 py-2  text-sm text-gray-700 transition-colors duration-300 transform rounded-md lg:mt-0 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700">
                                Offers
                            </Link>
                            <Link to="/login"
                                // onClick={handleLogout}
                                className=" px-4 py-2 font-sans text-xs font-bold text-center text-gray-900 uppercase align-middle transition-all rounded-lg select-none hover:bg-gray-900/10 active:bg-gray-900/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none lg:inline-block">                    Log In

                            </Link >
                            <Link to="/register"
                                // onClick={handleLogout}
                                className=" select-none rounded-lg bg-gradient-to-tr from-gray-900 to-gray-800 py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none lg:inline-block">
                                Sign UP

                            </Link >


                        </div>



                    </div>
                    <div className='block lg:hidden'>
                        <BurgerMenuIn/>
                </div>

                  
                </div>
            </div>
        </nav>

    )
}
export default NavbarIn