import React, { useContext, useEffect, useState } from 'react';
import LayoutProfile from '../layout/layoutProfile';
import MainSideProfile from '../components/profile/mainSideProfile';
import { useParams } from 'react-router-dom';
import {  getUserProfileByUsername } from '../api/userApi';
import { UserContext } from '../contexts/userContext';
import Footer from '../components/common/footer';
import { Spinner } from '@material-tailwind/react';
// import Profile from '../components/profile/profile';


const ProfilePage = () => {
  const { username } = useParams();
  const [loading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState(null);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const profileData = await getUserProfileByUsername(username);
        setProfileData(profileData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    fetchData();
  }, [username]);
  // console.log(profileData);
  // const handleOwnProfile = () => {
  //   if (user && user.userId === id) {
  //     // Additional logic for own profile, if needed
  //   }
  // };

  if (loading) {
    return <div><Spinner/></div>;
  }
  return (
    <>
      <LayoutProfile 
        child1={<MainSideProfile  profileData={profileData} />}
        // child2={<LeftSideProfile />} 
      />
      
    </>
    
  );
}

export default ProfilePage;
