import React, { useContext, useEffect } from 'react';
import Hero from '../components/home/hero';
import Footer from '../components/common/footer';
import Explore from '../components/home/explore';
import ExploreOffer from '../components/home/exploreOffer';
import ExploreUni from '../components/home/exploreUni.jsx';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/authContext.js';
import Contact from '../components/home/contact.jsx';


const HomePage = () => {
  // const { isAuthenticated } = useContext(AuthContext);
  // console.log(isAuthenticated);
  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (isAuthenticated()) {
  //     navigate('/dashboard');
  //   }
  // }, [isAuthenticated()]);


return (
    <div>
      <Hero/>
      <Explore/>
      <ExploreOffer/>
      <ExploreUni/>
      {/* <Contact/> */}
      <Footer/>
    </div>
    
  );
}

export default HomePage;
