// auth/AuthContext.js
import { createContext, useEffect, useState } from 'react';
// import { login, register } from '../api/authApi';

import {login,register} from '../api/authApi'
import { createCompany } from '../api/companyApi';
import { createStudent } from '../api/studentApi';
import { createUniversity } from '../api/universityApi';
import axios from 'axios';
const AuthContext = createContext();

const BASE_URL = process.env.REACT_APP_API_URL ;

const AuthProvider = ({ children }) => {
  // State to hold the token
  const [token, setToken] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);


  // const navigate = useNavigate();

  useEffect(() => {
    const handleAuthCallback = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const error = urlParams.get('error');
      
      if (error) {
        // Let the error be handled by the login component
        return;
      }
      
      if (code) {
        try {
          const response = await axios.post(`${BASE_URL}/auth/token`, { code });
          const { token, expiresAt, profileImage } = response.data;
          // console.log(response);
          
            const tokenData = {
              token,
              expiresAt,
              // profileImage // Include profile image in token data
            };

          localStorage.setItem('token', JSON.stringify(tokenData));
          localStorage.setItem('profileImage', JSON.stringify(profileImage));

          setIsLoggedIn(true);
          // setProfileImage(profileImage);
          // console.log(profileImage);
          

          
          window.location.href = '/dashboard';
        } catch (error) {
          console.error('Error exchanging code for token:', error);
          window.location.href = '/login?error=auth_failed';
        }
      }
    };
  
    handleAuthCallback();
  }, []);

  const googleAuth = (role) => {
    try {
      // Ensure the role is valid
      if (!['student', 'company', 'university'].includes(role)) {
        console.error('Invalid account type');
        return;
      }
      // Redirect for signup with role
      window.location.href = `${BASE_URL}/auth/google?role=${role}`;
    } catch (error) {
      console.error('Google Auth error:', error);
    }
  };
  
  const expiryTime = new Date().getTime() + 3600000;
  // Login function
  const register = async (credential, type) => {
  try {
    let response;
    switch (type) {
      case 'company':
        response = await createCompany(credential);
        break;
      case 'student':
        response = await createStudent(credential);
        break;
      case 'university':
        response = await createUniversity(credential);
        break;
      default:
        throw new Error('Invalid registration type');
    }

    const token = response.token;
    const tokenData = {
      token: token,
      expiresAt: expiryTime,
    };
    localStorage.setItem('token', JSON.stringify(tokenData));
    setIsLoggedIn(true);

    return response;
  } catch (error) {
    // Handle registration errors
    if (error.response && error.response.data) {
      // Extract error messages from the server response
      const { message, errors } = error.response.data;

      if (errors && Array.isArray(errors)) {
        throw new Error(errors.join(', ')); // Combine all errors into a single string
      }

      throw new Error(message || 'Failed to register');
    }

    // Fallback for other errors (network issues, etc.)
    throw new Error('Network error or server not responding');
  }
};


  
  const handleLogin = async (email, password) => {
    try {
      const credential = {
        email: email,
        password: password,
      };


      const response = await login(credential); // Use the login function from authApi.js
      // console.log(response);
      // Assuming the response contains a token
      const token = response.token;
      const tokenData = {
        token: token,
        expiresAt: expiryTime,
      };
      // setToken(token);
      localStorage.setItem('token', JSON.stringify(tokenData));
      setIsLoggedIn(true);
    } catch (error) {
      throw new Error('Failed to log in');
    }
  };

  const googleLogin = () => {
    try {
      // Redirect to Google login endpoint
      window.location.href = `${BASE_URL}/auth/google/login`;
    } catch (error) {
      console.error('Google Login error:', error);
    }
  };
//   const ForgetPassword = async (email,) => {
//     try {}
//   catch(error){
// throw new Error('Failed send mail')
//   }}

  // Logout function
  const handleLogout = () => {
    // if (isAuthenticated()) {
    // Clear the token or perform any necessary cleanup
    // setToken(null);
    localStorage.removeItem('token');
    localStorage.removeItem('profileImage');
    setIsLoggedIn(false);
    //   }
  };

  // Check if the user is authenticated
  const isAuthenticated = () => {
    const tokenDataString = localStorage.getItem('token');
    if (tokenDataString) {
      const tokenData = JSON.parse(tokenDataString);
      const currentTime = new Date().getTime();

      if (currentTime > tokenData.expiresAt) {
        // Token has expired, remove it from local storage
        localStorage.removeItem('token');
        localStorage.removeItem('profileImage');

        return false;
      } else {
        // Token is still valid
        return true;
      }
    }
    return false;
};


  // Provide the context values to the components
  const authContextValues = {
    token,
    login: handleLogin,
    register:register,
    logout: handleLogout,
     isAuthenticated,
     googleAuth,
     googleLogin
  };

  return (
    <AuthContext.Provider value={authContextValues}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export { AuthContext, AuthProvider };
