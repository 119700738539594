import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL ; // Replace with your backend API base URL

const tokenString = localStorage.getItem('token');

let headers = {};

if (tokenString) {
  const tokenObject = JSON.parse(tokenString);
  const token = tokenObject.token;

  headers = {
    Authorization: token,
  };
}

// Fetch all companies
export const getCompanies = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/companies`
    // ,{headers: headers,  }
    );
    return response.data;
  } catch (error) {
    // Handle error
    console.error('Error fetching companies:', error);
    throw new Error('Failed to fetch companies');
  }
};

// Fetch a single company by ID
export const getCompanyById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/companies/${id}`
    // ,{headers: headers,  }
    );
    return response.data;
  } catch (error) {
    // Handle error
    console.error(`Error fetching company with ID ${id}:`, error);
    throw new Error(`Failed to fetch company with ID ${id}`);
  }
};
// Fetch company by user ID
export const getCompanyByUserId = async (userId) => {
  try {
    const response = await axios.get(`${BASE_URL}/companies/user/${userId}`/* , { headers } */);
    return response.data;
  } catch (error) {
    console.error(`Error fetching company with userId ${userId}:`, error);
    throw new Error(`Failed to fetch company with userId ${userId}`);
  }
};

// Create a new company
export const createCompany = async (companyData) => {
  try {
    const response = await axios.post(`${BASE_URL}/companies`, companyData
    // ,{headers: headers,  }
    );
    // console.log(response);
    return response.data;
  } catch (error) {
    // Don't replace the error; re-throw it
    if (error.response && error.response.data) {
      throw error; // Pass the original error to the caller
    }
    // For network-related errors
    throw new Error('Network error or server not responding');
  }
};

// Update an existing company
export const updateCompany = async (id, companyData) => {
  try {
    const response = await axios.put(`${BASE_URL}/companies/${id}`, companyData
    // ,{headers: headers,  }
    );
    return response.data;
  } catch (error) {
    // Handle error
    console.error(`Error updating company with ID ${id}:`, error);
    throw new Error(`Failed to update company with ID ${id}`);
  }
};

// Delete a company by ID
export const deleteCompany = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/companies/${id}`
    // ,{headers: headers,  }
    );
    return response.data;
  } catch (error) {
    // Handle error
    console.error(`Error deleting company with ID ${id}:`, error);
    throw new Error(`Failed to delete company with ID ${id}`);
  }
};
