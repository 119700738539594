import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL ; // Replace with your backend API base URL

const tokenString = localStorage.getItem('token');

let headers = {};

if (tokenString) {
  const tokenObject = JSON.parse(tokenString);
  const token = tokenObject.token;

  headers = {
    Authorization: token,
  };
}

// Fetch all universities
export const getUniversities = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/universities`
    // ,{headers: headers,  }
    );
    return response.data;
  } catch (error) {
    // Handle error
    console.error('Error fetching universities:', error);
    throw new Error('Failed to fetch universities');
  }
};

// Fetch a single university by ID
export const getUniversityById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/universities/${id}`
    // ,{headers: headers,  }
    );
    return response.data;
  } catch (error) {
    // Handle error
    // console.error(`Error fetching university with ID ${id}:`, error);
    throw new Error(`Failed to fetch university with ID ${id}`);
  }
};

// Fetch university by user ID
export const getUniversityByUserId = async (userId) => {
  try {
    const response = await axios.get(`${BASE_URL}/universities/user/${userId}`/* , { headers } */);
    return response.data;
  } catch (error) {
    console.error(`Error fetching university with userId ${userId}:`, error);
    throw new Error(`Failed to fetch university with userId ${userId}`);
  }
};
// Create a new university
export const createUniversity = async (universityData) => {
  try {
    const response = await axios.post(`${BASE_URL}/universities`, universityData
    // ,{headers: headers,  }
    );
    // console.log(response);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      throw error;
    }
    throw new Error('Network error or server not responding');
  }
};

// Update an existing university
export const updateUniversity = async (id, universityData) => {
  try {
    const response = await axios.put(`${BASE_URL}/universities/${id}`, universityData
    // ,{headers: headers,  }
    );
    return response.data;
  } catch (error) {
    // Handle error
    console.error(`Error updating university with ID ${id}:`, error);
    throw new Error(`Failed to update university with ID ${id}`);
  }
};

// Delete a university by ID
export const deleteUniversity = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/universities/${id}`
    // ,{headers: headers,  }
    );
    return response.data;
  } catch (error) {
    // Handle error
    console.error(`Error deleting university with ID ${id}:`, error);
    throw new Error(`Failed to delete university with ID ${id}`);
  }
};
