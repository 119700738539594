import { AuthContext } from '../../contexts/authContext';
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import GoogleLoginButton from './googleLoginButton';

// import Profile from '../components/profile/profile';


const LoginForm = ({ setIsAuthenticated }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // Renamed to avoid conflict
  const [showPassword, setShowPassword] = useState(false);


  const { login,  } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation(); // Extract location to access query params

   const getQueryParams = (search) => {
    const params = new URLSearchParams(search);
    return {
      error: params.get('error'),
      message: params.get('message'),
    };
  };

  // Handle query parameters on component load
  useEffect(() => {
    const { error, message } = getQueryParams(location.search);

    if (error) {
      setErrorMessage(message || 'An error occurred. Please try again.');
    }
  }, [location.search]);


  const handleFormSubmit = async (e) => {

    e.preventDefault();

    try {
      await login(email, password);
 
      setErrorMessage(''); // Clear any existing error

      navigate("/loading");

      // setError('');
      setIsAuthenticated(true);

    } catch (error) {
      setErrorMessage('Failed to log in. Please check your credentials.');
    }
  };




  return (

    <div className="mx-auto bg-white max-w-lg my-12 p-4 shadow-md rounded-lg bg-clip-border">
      <h1 className="text-center pt-8 text-2xl font-bold text-indigo-600 sm:text-3xl">Welcome Back</h1>

      {/* <p className="mx-auto mt-4 max-w-md text-center text-gray-500 ">
        Welcome back
      </p> */}
        {errorMessage  && (
        <div className="mt-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <span className="block sm:inline">{errorMessage}</span>
        </div>
      )}
      <form
        onSubmit={handleFormSubmit}
        className="flex flex-col gap-2 mb-0 mt-4   p-4  sm:p-6 lg:p-2">

        
          <label className='ml-2 self-start  block text-base font-medium text-dark dark:text-white'>
            Email
          </label>
          <div className="relative mb-4">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
              placeholder="Enter email"
            />

            <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="size-4 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                />
              </svg>
            </span>
          </div>
        
          <label className="ml-2 self-start block text-base font-medium text-dark dark:text-white">
  Password
</label>
<div className="relative">
  <input
    type={showPassword ? "text" : "password"} // Dynamically set input type
    value={password}
    onChange={(e) => setPassword(e.target.value)}
    className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
    placeholder="Enter password"
  />
  <span
    className="absolute inset-y-0 end-0 grid place-content-center px-4 cursor-pointer"
    onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
  >
    {showPassword ? (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      className="size-4 text-gray-400"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
      />
    </svg>
               ) : (
                <svg
              xmlns="http://www.w3.org/2000/svg"
              className="size-4 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
               )}
            </span>
          </div>
        <div className='mt-6 flex flex-col gap-4'>

          <button
            type="submit"
            className=" block w-full rounded-lg bg-indigo-600 px-5 py-3 text-sm font-medium text-white"
          >
            Log In
          </button>
        </div>
        
        
         </form>
         <div className='mb-6 flex flex-col  gap-2   p-4  sm:p-6 lg:p-2'>
         <GoogleLoginButton />
</div>
        <p className="text-center text-sm text-gray-500">
          You don't have an account?
          <Link to="/register" className="underline" >Sign up</Link>
        </p>
     
    </div>
  );
}

export default LoginForm;


