import React, { useContext, useEffect, useState } from 'react';
import { Progress, Typography } from "@material-tailwind/react";
import { Link } from 'react-router-dom';

export default function ProfileCard({firstName, lastName}) {
    const [profileImage, setProfileImage] = useState(null);

  useEffect(() => {
    // Retrieve profileImage from localStorage
    const storedProfileImage = localStorage.getItem('profileImage');
    if (storedProfileImage) {
      setProfileImage(JSON.parse(storedProfileImage));
    }
  }, []);
    // const profileImage = getProfileImage();
    
  
    return (
        <div className='bg-white p-4  shadow-md rounded-xl bg-clip-border '>
            <div className=' flex gap-4 items-center '>

                <div className="hidden sm:block sm:shrink-0 ">
             
        {/* <img
          src={profileImage || "/studentProfile.jpeg"}
          alt="Profile"
          className='h-24 w-24'
        /> */}
         {profileImage ? (
        <img src={profileImage} alt="Profile" className="rounded-full h-24 w-24" />
      ) : (
        
          <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-user-circle h-24 w-24"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /><path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" /><path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855" />
        </svg>
      )}
      
      
                </div>
                

                <div className='flex flex-col flex-grow truncate ..'>
                    <span className='overflow-hidden font-semibold text-lg text-left ml-2 text-wrap '>
                        {/* <p className=''>
                            Hello
                        </p> */}{firstName}
                    </span>
                    <span className='overflow-hidden font-semibold text-lg text-left  ml-2 text-wrap '>
                        <p className='truncate ..'>
                             {lastName}
                        </p>
                    </span>
                </div>

                <Link to='/account' className='basis-1/4 bg-gray-200 p-2  shadow-md rounded-xl bg-clip-border'>
                <div className='flex gap-1 '>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                        </svg></div>
                    <h1 className='text-xs'>
                        Complete 
                    </h1>


                </div>
                <h1 className='text-xs'>
                    your profile
                </h1>
                
                </Link>

            </div>
            <div className='mt-2'>
                <Progress value={50} />
            </div>
            <div className="flex items-center justify-between gap-4">
                {/* <Typography color="blue-gray" variant="h6"> */}
                <span className='text-sm'>
                    Completed
                </span>
                {/* </Typography> */}
                {/* <Typography color="blue-gray" variant="h6"> */}
                <span className='text-sm'>

                    50%
                </span>

                {/* </Typography> */}
            </div>

        </div>
    )
}